.about-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  max-width: 80vw;
  /* border: 1px solid red; */
}

.about-title {
  color: var(--theme-black);
  font-size: 4rem;
  max-width: 100%;
  text-align: center;
  /* border: 1px solid black; */
}

.about-body {
  max-width: 1000px;
  margin: 0 auto;
  /* border: 1px solid black; */
}

/* Content for first fold */
.about-first-fold {
  width: 100%;
}

.about-contact {
  display: flex;
  align-items: center;
  list-style: none;
  /* border: 1px solid red; */
}

.about-contact-li {
  color: var(--theme-black);
  font-size: 2rem;
  /* border: 1px solid blue; */
}

.github-link {
  padding-right: 1em;
  color: var(--theme-black);
  transition: all 500ms ease-in-out;
}

.github-link:hover {
  color: var(--theme-mainAccent);
}

.linkedin-link {
  padding-right: 1em;
  color: var(--theme-black);
  transition: all 500ms ease-in-out;
}

.linkedin-link:hover {
  color: var(--theme-mainAccent);
}

/* Content for Resume Download Link */
.about-description {
  margin-left: 4em;
  color: var(--theme-darkgrey);
  font-size: 1.4rem;
  padding-right: 1.5rem;
  /* border: 1px solid black; */
}

.resume-link:visited {
  text-decoration: none;
}

.resume-download-icon {
  color: var(--theme-black);
  transition: all 500ms ease-in-out;
  padding-left: 0.5em;
}

.resume-download-icon:hover {
  color: var(--theme-mainAccent);
}

/* Interests Portion of About Page */
.interests-title {
  color: var(--theme-black);
  font-size: 2rem;
  margin-left: 1em;
}

.interests-li {
  list-style: none;
  color: var(--theme-darkgrey);
  font-size: 1.4rem;
  padding-right: 2em;
}

.interests-ul {
  display: flex;
}

.interests-items {
  margin: 0.5em;
}

/* Skills Portion on About Page */
.skills-title {
  color: var(--theme-black);
  font-size: 2rem;
  margin-left: 1em;
}

.skills-ul {
  display: flex;
  max-width: 90%;
  /* border: 1px solid black; */
}

.skills-li {
  color: var(--theme-darkgrey);
  list-style: none;
  font-size: 1.4rem;
  padding-right: 2em;
  width: 100%;
  /* border: 1px solid black; */
}

.skills-items {
  margin: 0.5rem;
}

/* Content for Work Experience Section */
.work-experience-title {
  color: var(--theme-black);
  font-size: 2rem;
  margin-left: 1em;
}

.work-experience-ul {
  display: flex;
}

.work-experience-li {
  color: var(--theme-darkgrey);
  list-style: none;
  font-size: 1.4rem;
  padding-right: 2em;
}

@media screen and (max-width: 900px){
  .about-title {
    font-size: 3rem;
  }
  
  .about-description {
    font-size: 1.3rem;
  }

  .interests-title {
    font-size: 1.8rem;
  }

  .interests-items {
    font-size: 1.3rem;
  }

  .skills-title {
    font-size: 1.8rem;
  }

  .skills-items {
    font-size: 1.3rem;
  }

  .work-experience-title {
    font-size: 1.8rem;
  }

  .work-experience-li {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 500px){
  .about-title {
    font-size: 2.4rem;
  }
  
  .about-contact {
    padding-left: 1em;
  }

  .about-description {
    margin-left: 0;
    padding-left: 1em;
  }

  .about-contact-li {
    font-size: 1.6rem;
  }

  .interests-title {
    margin-bottom: 0;
  }
  
  .interests-ul {
    display: block;
  }

  .skills-title {
    margin-bottom: 0;
  }
  
  .skills-ul {
    display: block;
    width: 50%;
  }

  .skills-items {
    width: 100%;
  }

  .work-experience-title {
    margin-bottom: 0
  }
}
