.socialBar {
  position: fixed;
  width: 5vw;
  min-height: 100vh;
  overflow: auto;
  right: 0;
  /* border: 1px solid blue; */
}

.socialBar-container {
  position: absolute;
  width: 100%;
  height: 25%;
  margin-top: 40vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  /* border: 1px solid black; */
}

.social-icons {
  color: var(--theme-black);
  text-decoration: none;
  /* border: 1px solid red; */
  transition: all 500ms ease-in-out;
}

.social-icons:hover {
  color: var(--theme-mainAccent);
}

@media screen and (max-height:700px){
  .socialBar-container {
    height: 75%;
    margin: 8em 0 8em 0;
  }
}

@media screen and (max-height:400px){
  .socialBar-container {
    height: 100%;
    margin: 0;
  }
}
