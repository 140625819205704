.work-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  max-width: 80vw;
  /* border: 1px solid red; */
}

.title-container {
  width: 100%;
  height: 10vh;
  /* border: 1px solid black; */
}

.work-title {
  font-size: 3rem;
}

.project-section {
  min-height: 40vh;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.project-image-odd {
  width: 500px;
  height: auto;
  border-radius: 6px;
}

.project-image-even {
  width: 500px;
  height: auto;
  border-radius: 6px;
  margin-left: 4rem;
}

.project-information-odd {
  height: 100%;
  margin-left: 4rem;
  width: 40%;
  /* border: 1px solid black; */
}

.project-information-even {
  height: 100%;
  max-width: 40%;
  /* border: 1px solid black; */
}

.project-team-odd {
  color: var(--theme-black);
  text-align: right;
  font-size: 1.4rem;
}

.project-team-even {
  color: var(--theme-black);
  text-align: left;
  font-size: 1.4rem;
}

.project-name-odd {
  color: var(--theme-black);
  text-align: right;
  font-size: 2.2rem;
}

.project-name-even {
  color: var(--theme-black);
  text-align: left;
  font-size: 2.2rem;
}

.project-description-odd {
  color: var(--theme-black);
  background-color: var(--theme-hovercolor);
  text-align: right;
  padding-right: 10px;
}

.project-description-even {
  color: var(--theme-black);
  background-color: var(--theme-hovercolor);
  text-align: left;
  padding-right: 10px;
}

.project-tools-list-odd {
  list-style: none;
  display: flex;
  flex-wrap: none;
  flex-direction: row;
  justify-content: flex-end;
}

.project-tools-list-even {
  list-style: none;
  display: flex;
  flex-wrap: none;
  flex-direction: row;
  justify-content: flex-start;
}

.project-tools-odd {
  display: list-item;
  text-align: right;
  margin-right: 2.5rem;
}

.project-tools-even {
  display: list-item;
  text-align: left;
  margin-right: 2.5rem;
}

.project-links-odd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.project-links-even {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.github-icon-odd {
  color: var(--theme-black);
  text-decoration: none;
  padding-right: 10px;
  transition: all 500ms ease-in-out;
}

.github-icon-even {
  color: var(--theme-black);
  text-decoration: none;
  padding-left: 10px;
  transition: all 500ms ease-in-out;
}

.github-icon-odd:hover {
  color: var(--theme-mainAccent);
}

.github-icon-even:hover {
  color: var(--theme-mainAccent);
}

.crypto-img {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  filter: grayscale(100%);
  transition: 500ms all ease-in-out;
}

.crypto-img:hover {
  filter: none;
}

.vid-container {
  max-height: 100%;
  max-width: 100%;
  border-radius: 6px;
}

.other-project-container {
  min-height: 40vh;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.other-projects-title {
  width: 100%;
  text-align: center;
}

.other-projects {
  height: 30vh;
  width: 15vw;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* border: 1px solid black; */
}

.other-projects:hover {
  top: -10px;
}

.oproject-top-container {
  /* border: 1px solid blue; */
  max-width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.folder-icon-container {
  color: var(--theme-black);
  /*border: 1px solid red;*/
  width: fit-content;
  text-decoration: none;
}

.folder-icon-container a:visited {
  text-decoration: none;
  color: var(--theme-black);
}

.openwith-icon-container {
  color: var(--theme-black);
  /*border: 1px solid red;*/
  width: fit-content;
}

.openwith-icon-container a:visited {
  color: var(--theme-black);
  text-decoration: none;
}

.oproject-title-container {
  /*border: 1px solid blue;*/
  max-width: 100%;
}

.oproject-title {
  color: var(--theme-black);
}

.oproject-description-container {
  /*border: 1px solid blue;*/
  width: 100%;
}

.oproject-description {
  color: var(--theme-darkgrey);
}

.oproject-tools-container {
  /*border: 1px solid blue;*/
  width: 100%;
}

.oproject-tools-list {
  /* border: 1px solid blue; */
  max-width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: none;
  flex-direction: row;
  padding: 0;
  justify-content: flex-start;
}

.oproject-tool {
  font-size: 10px;
  margin-right: 10px;
  color: var(--theme-mainAccent);
  /* border: 1px solid green; */
}

@media screen and (max-width:1200px){
  .other-projects {
    width: 20vw;
  }

  .project-tools-even {
    font-size: .8rem;
  }

  .project-tools-odd {
    font-size: .8rem;
  }
}

@media screen and (max-width:750px){
  .title-container {
    height: auto;
  }

  .work-title {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
  
  .other-projects {
    width: 30vw;
    height: auto;
  }

  .project-name-even {
    font-size: 1.2rem;
  }

  .project-name-odd {
    font-size: 1.2rem;
  }

  .project-tools-list-even {
    flex-wrap: wrap;
  }
  
  .project-tools-even {
    min-width: 100%;
  }

  .project-tools-list-odd {
    flex-wrap: wrap;
  }
  
  .project-tools-odd {
    min-width: 100%;
  }
}

@media screen and (max-width:500px){
  .other-project-container {
    justify-content: center;
  }

  .other-projects {
    width: 40vw;
  }
}

@media screen and (max-width:400px){
  .work-title {
    font-size: 2rem;
    text-align: left;
  }

  .project-section {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .project-information-even {
    min-width: 100%;
  }

  .project-information-odd {
    min-width: 100%;
    margin-left: 0;
    order: 1;
  }

  .project-image-even {
    max-width: 100%;
    margin-left: 0;
  }

  .project-image-odd {
    max-width: 100%;
    order: 2;
  }


  .project-team-even {
    font-size: 1.6rem;
  }

  .project-team-odd {
    font-size: 1.6rem;
  }
  
  .oproject-tools-list {
    flex-wrap: wrap;
  }
}
