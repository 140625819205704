@import url(https://fonts.googleapis.com/css2?family=Aldrich&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pirata+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(40, 44, 52);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Animation Speed */
  --animspeed: 500ms;

  /* Main Theme 2022*/
  --theme-background: #ffffff;
  --theme-mainAccent: #f19dac;
  --theme-black: #000000;
  --theme-darkgrey: #6c757d;
  --theme-lightgrey: #ced4da;
  --theme-hovercolor: #e9ecef;

  /* Milkshake Theme */
  --milkshake-light: #ffffff;
  --milkshake-dark: #212b43;
  --milkshake-lightblue: #62cfe6;
  --milkshake-red: #f19dac;
  --milkshake-yellow: #f6f4a0;
  --milkshake-green: #73e4d0;
  --milkshake-purple: #ba96db;

  font-size: 10px;
}

* {
  background-color: #ffffff;
  background-color: var(--theme-background);
  font-family: "Roboto Mono";
}

.nav-header {
  position: fixed;
  width: 5vw;
  min-height: 100vh;
  overflow: auto;
  left: 0;
  /* border: 1px solid green; */
}

.nav-header-container {
  position: absolute;
  width: 100%;
  height: 25%;
  margin-top: 40vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.nav-header-img {
  max-width: 100%;
  margin: 0 auto 0 auto;
  /* border: 1px solid black; */
}

/* Nav Link Styling */

.navLink {
  color: var(--theme-black);
  text-decoration: none;
  transition: all 500ms ease-in-out;
  /* border: 1px solid red; */
}

.navLink:hover {
  color: var(--theme-mainAccent);
}

@media screen and (max-height: 700px){
  .nav-header-container {
    height: 75%;
    margin: 8em 0 8em 0;
  }
}

@media screen and (max-height:400px){
  .nav-header-container {
    height: 100%;
    margin: 0;
  }
}

@media screen and (max-width:700px){
  .nav-header-img {
    display: none;
  }
}
.socialBar {
  position: fixed;
  width: 5vw;
  min-height: 100vh;
  overflow: auto;
  right: 0;
  /* border: 1px solid blue; */
}

.socialBar-container {
  position: absolute;
  width: 100%;
  height: 25%;
  margin-top: 40vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  /* border: 1px solid black; */
}

.social-icons {
  color: var(--theme-black);
  text-decoration: none;
  /* border: 1px solid red; */
  transition: all 500ms ease-in-out;
}

.social-icons:hover {
  color: var(--theme-mainAccent);
}

@media screen and (max-height:700px){
  .socialBar-container {
    height: 75%;
    margin: 8em 0 8em 0;
  }
}

@media screen and (max-height:400px){
  .socialBar-container {
    height: 100%;
    margin: 0;
  }
}

.greeting-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  height: 100vh;
  min-width: 80vw;
  background-color: var(--theme-background);
  /* border: 1px solid red; */
}

.introBox {
  margin: 10em 0 10em 5em;
  max-width: 600px;
  /* border: 1px solid green; */
}

.homepage-greeting {
  color: var(--theme-black);
  font-size: 2.8rem;
  /* border: 1px solid black; */
}

.nameBanner {
  color: var(--theme-black);
  font-size: 4.5rem;
  /* border: 1px solid black; */
}

.roleBanner {
  color: var(--theme-black);
  font-size: 2.8rem;
  /*border: 1px solid black;*/
}

.homepage-subgreeting {
  font-size: 1.8rem;
  max-width: 700px;
  margin: 0 0 5em 5em;
  /*border: 1px solid purple;*/
}

.homepage-summary {
  color: var(--theme-black);
  background-color: transparent;
}

.picture-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 4em;
  /* border: 1px solid sienna; */
}


.image-container {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  border-radius: 50%;
  max-width: 100%;
}

@media screen and (max-width: 950px){
  .homepage-subgreeting {
    margin-right: 1.5em;
  }
}

@media screen and (max-width: 700px){
  .introBox {
    max-width: 100%;
    margin: 5em 0 5em 0;
    text-align: center;
    /* border: 1px solid black; */
  }

  .homepage-greeting {
    font-size: 2rem;
  }

  .nameBanner {
    font-size: 3.8rem;
  }

  .roleBanner {
    font-size: 2rem;
  }

  .homepage-subgreeting {
    max-width: 100%;
    text-align: center;
    margin: 0 0 2em 0;
    font-size: 1.6rem;
    /* border: 1px solid black; */
  }

  .picture-container {
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    /* border: 1px solid black; */
  }

  .image-container {
    scale: .75;
  }
}

@media screen and (max-width:400px){
  .nameBanner {
    font-size: 3rem;
  }
  
  .homepage-subgreeting {
    font-size: 1.4rem;
    margin: 0 1em 0 1em;
  }

  .image-container {
    height: 250px;
  }
}
.work-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  max-width: 80vw;
  /* border: 1px solid red; */
}

.title-container {
  width: 100%;
  height: 10vh;
  /* border: 1px solid black; */
}

.work-title {
  font-size: 3rem;
}

.project-section {
  min-height: 40vh;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.project-image-odd {
  width: 500px;
  height: auto;
  border-radius: 6px;
}

.project-image-even {
  width: 500px;
  height: auto;
  border-radius: 6px;
  margin-left: 4rem;
}

.project-information-odd {
  height: 100%;
  margin-left: 4rem;
  width: 40%;
  /* border: 1px solid black; */
}

.project-information-even {
  height: 100%;
  max-width: 40%;
  /* border: 1px solid black; */
}

.project-team-odd {
  color: var(--theme-black);
  text-align: right;
  font-size: 1.4rem;
}

.project-team-even {
  color: var(--theme-black);
  text-align: left;
  font-size: 1.4rem;
}

.project-name-odd {
  color: var(--theme-black);
  text-align: right;
  font-size: 2.2rem;
}

.project-name-even {
  color: var(--theme-black);
  text-align: left;
  font-size: 2.2rem;
}

.project-description-odd {
  color: var(--theme-black);
  background-color: var(--theme-hovercolor);
  text-align: right;
  padding-right: 10px;
}

.project-description-even {
  color: var(--theme-black);
  background-color: var(--theme-hovercolor);
  text-align: left;
  padding-right: 10px;
}

.project-tools-list-odd {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: none;
          flex-wrap: none;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.project-tools-list-even {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: none;
          flex-wrap: none;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.project-tools-odd {
  display: list-item;
  text-align: right;
  margin-right: 2.5rem;
}

.project-tools-even {
  display: list-item;
  text-align: left;
  margin-right: 2.5rem;
}

.project-links-odd {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.project-links-even {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.github-icon-odd {
  color: var(--theme-black);
  text-decoration: none;
  padding-right: 10px;
  transition: all 500ms ease-in-out;
}

.github-icon-even {
  color: var(--theme-black);
  text-decoration: none;
  padding-left: 10px;
  transition: all 500ms ease-in-out;
}

.github-icon-odd:hover {
  color: var(--theme-mainAccent);
}

.github-icon-even:hover {
  color: var(--theme-mainAccent);
}

.crypto-img {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: 500ms all ease-in-out;
}

.crypto-img:hover {
  -webkit-filter: none;
          filter: none;
}

.vid-container {
  max-height: 100%;
  max-width: 100%;
  border-radius: 6px;
}

.other-project-container {
  min-height: 40vh;
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 30px;
}

.other-projects-title {
  width: 100%;
  text-align: center;
}

.other-projects {
  height: 30vh;
  width: 15vw;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* border: 1px solid black; */
}

.other-projects:hover {
  top: -10px;
}

.oproject-top-container {
  /* border: 1px solid blue; */
  max-width: 100%;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.folder-icon-container {
  color: var(--theme-black);
  /*border: 1px solid red;*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

.folder-icon-container a:visited {
  text-decoration: none;
  color: var(--theme-black);
}

.openwith-icon-container {
  color: var(--theme-black);
  /*border: 1px solid red;*/
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.openwith-icon-container a:visited {
  color: var(--theme-black);
  text-decoration: none;
}

.oproject-title-container {
  /*border: 1px solid blue;*/
  max-width: 100%;
}

.oproject-title {
  color: var(--theme-black);
}

.oproject-description-container {
  /*border: 1px solid blue;*/
  width: 100%;
}

.oproject-description {
  color: var(--theme-darkgrey);
}

.oproject-tools-container {
  /*border: 1px solid blue;*/
  width: 100%;
}

.oproject-tools-list {
  /* border: 1px solid blue; */
  max-width: 100%;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: none;
          flex-wrap: none;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 0;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.oproject-tool {
  font-size: 10px;
  margin-right: 10px;
  color: var(--theme-mainAccent);
  /* border: 1px solid green; */
}

@media screen and (max-width:1200px){
  .other-projects {
    width: 20vw;
  }

  .project-tools-even {
    font-size: .8rem;
  }

  .project-tools-odd {
    font-size: .8rem;
  }
}

@media screen and (max-width:750px){
  .title-container {
    height: auto;
  }

  .work-title {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
  
  .other-projects {
    width: 30vw;
    height: auto;
  }

  .project-name-even {
    font-size: 1.2rem;
  }

  .project-name-odd {
    font-size: 1.2rem;
  }

  .project-tools-list-even {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  
  .project-tools-even {
    min-width: 100%;
  }

  .project-tools-list-odd {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  
  .project-tools-odd {
    min-width: 100%;
  }
}

@media screen and (max-width:500px){
  .other-project-container {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .other-projects {
    width: 40vw;
  }
}

@media screen and (max-width:400px){
  .work-title {
    font-size: 2rem;
    text-align: left;
  }

  .project-section {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    max-width: 100%;
  }

  .project-information-even {
    min-width: 100%;
  }

  .project-information-odd {
    min-width: 100%;
    margin-left: 0;
    -webkit-order: 1;
            order: 1;
  }

  .project-image-even {
    max-width: 100%;
    margin-left: 0;
  }

  .project-image-odd {
    max-width: 100%;
    -webkit-order: 2;
            order: 2;
  }


  .project-team-even {
    font-size: 1.6rem;
  }

  .project-team-odd {
    font-size: 1.6rem;
  }
  
  .oproject-tools-list {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.about-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  max-width: 80vw;
  /* border: 1px solid red; */
}

.about-title {
  color: var(--theme-black);
  font-size: 4rem;
  max-width: 100%;
  text-align: center;
  /* border: 1px solid black; */
}

.about-body {
  max-width: 1000px;
  margin: 0 auto;
  /* border: 1px solid black; */
}

/* Content for first fold */
.about-first-fold {
  width: 100%;
}

.about-contact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  list-style: none;
  /* border: 1px solid red; */
}

.about-contact-li {
  color: var(--theme-black);
  font-size: 2rem;
  /* border: 1px solid blue; */
}

.github-link {
  padding-right: 1em;
  color: var(--theme-black);
  transition: all 500ms ease-in-out;
}

.github-link:hover {
  color: var(--theme-mainAccent);
}

.linkedin-link {
  padding-right: 1em;
  color: var(--theme-black);
  transition: all 500ms ease-in-out;
}

.linkedin-link:hover {
  color: var(--theme-mainAccent);
}

/* Content for Resume Download Link */
.about-description {
  margin-left: 4em;
  color: var(--theme-darkgrey);
  font-size: 1.4rem;
  padding-right: 1.5rem;
  /* border: 1px solid black; */
}

.resume-link:visited {
  text-decoration: none;
}

.resume-download-icon {
  color: var(--theme-black);
  transition: all 500ms ease-in-out;
  padding-left: 0.5em;
}

.resume-download-icon:hover {
  color: var(--theme-mainAccent);
}

/* Interests Portion of About Page */
.interests-title {
  color: var(--theme-black);
  font-size: 2rem;
  margin-left: 1em;
}

.interests-li {
  list-style: none;
  color: var(--theme-darkgrey);
  font-size: 1.4rem;
  padding-right: 2em;
}

.interests-ul {
  display: -webkit-flex;
  display: flex;
}

.interests-items {
  margin: 0.5em;
}

/* Skills Portion on About Page */
.skills-title {
  color: var(--theme-black);
  font-size: 2rem;
  margin-left: 1em;
}

.skills-ul {
  display: -webkit-flex;
  display: flex;
  max-width: 90%;
  /* border: 1px solid black; */
}

.skills-li {
  color: var(--theme-darkgrey);
  list-style: none;
  font-size: 1.4rem;
  padding-right: 2em;
  width: 100%;
  /* border: 1px solid black; */
}

.skills-items {
  margin: 0.5rem;
}

/* Content for Work Experience Section */
.work-experience-title {
  color: var(--theme-black);
  font-size: 2rem;
  margin-left: 1em;
}

.work-experience-ul {
  display: -webkit-flex;
  display: flex;
}

.work-experience-li {
  color: var(--theme-darkgrey);
  list-style: none;
  font-size: 1.4rem;
  padding-right: 2em;
}

@media screen and (max-width: 900px){
  .about-title {
    font-size: 3rem;
  }
  
  .about-description {
    font-size: 1.3rem;
  }

  .interests-title {
    font-size: 1.8rem;
  }

  .interests-items {
    font-size: 1.3rem;
  }

  .skills-title {
    font-size: 1.8rem;
  }

  .skills-items {
    font-size: 1.3rem;
  }

  .work-experience-title {
    font-size: 1.8rem;
  }

  .work-experience-li {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 500px){
  .about-title {
    font-size: 2.4rem;
  }
  
  .about-contact {
    padding-left: 1em;
  }

  .about-description {
    margin-left: 0;
    padding-left: 1em;
  }

  .about-contact-li {
    font-size: 1.6rem;
  }

  .interests-title {
    margin-bottom: 0;
  }
  
  .interests-ul {
    display: block;
  }

  .skills-title {
    margin-bottom: 0;
  }
  
  .skills-ul {
    display: block;
    width: 50%;
  }

  .skills-items {
    width: 100%;
  }

  .work-experience-title {
    margin-bottom: 0
  }
}

.contact-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  height: 100vh;
  max-width: 90vw;
  /* border: 1px solid red; */
}

.page-title {
  color: var(--theme-black);
  font-size: 2.4rem;
  padding: 0 1em;
  margin: 10rem 0 5rem 0;
  max-width: 100%;
  text-align: center;
  /* border: 1px solid black; */
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 700px;
  padding: 1em;
  margin: 0 auto;
  /* border: 1px solid black; */
}

.form-label {
  color: var(--theme-black);
  font-size: 2.4rem;
  /* border: 1px solid red; */
}

.form-input {
  color: var(--theme-black);
  border-color: var(--theme-darkgrey);
  border-radius: 1rem;
  border-width: .4rem;
  font-size: 2rem;
  width: 100%;
}

::-webkit-input-placeholder {
  color: var(--theme-lightgrey);
}

:-ms-input-placeholder {
  color: var(--theme-lightgrey);
}

::placeholder {
  color: var(--theme-lightgrey);
}

.form-submit {
  color: var(--theme-black);
  font-size: 1.8rem;
  border-color: var(--theme-lightgrey);
  border-radius: 1rem;
  border-width: .5rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-max-content;
  width: max-content;
  transition: all 400ms ease-in-out;
}

.form-submit:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  color: var(--milkshake-green);
}

.form-message {
  color: var(--theme-darkgrey);
  border-color: var(--theme-black);
  border-width: 3px;
  border-radius: 10px;
  font-size: 20px;
  height: 120px;
  max-width: 100%;
}

@media screen and (max-width: 400px){
  .page-title {
    margin: 2rem 0 5rem 0;
  }
}

@media screen and (max-width:300px){
  .contact-content {
    left: 5vw;
    right: 5vw;
    min-width: 50vw;
    /* border: 1px solid green; */
  }
  
  .page-title {
    margin-bottom: 0;
  }

  .form-label {
    font-size: 1.8rem;
    width: -webkit-max-content;
    width: max-content;
  }

  .form-input {
    width: 50%;
    font-size: 1.4rem;
  }

  .form-submit {
    margin-top: 1em;
    margin-left: 0;
  }
}


