@import url("https://fonts.googleapis.com/css2?family=Pirata+One&display=swap");


.greeting-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  height: 100vh;
  min-width: 80vw;
  background-color: var(--theme-background);
  /* border: 1px solid red; */
}

.introBox {
  margin: 10em 0 10em 5em;
  max-width: 600px;
  /* border: 1px solid green; */
}

.homepage-greeting {
  color: var(--theme-black);
  font-size: 2.8rem;
  /* border: 1px solid black; */
}

.nameBanner {
  color: var(--theme-black);
  font-size: 4.5rem;
  /* border: 1px solid black; */
}

.roleBanner {
  color: var(--theme-black);
  font-size: 2.8rem;
  /*border: 1px solid black;*/
}

.homepage-subgreeting {
  font-size: 1.8rem;
  max-width: 700px;
  margin: 0 0 5em 5em;
  /*border: 1px solid purple;*/
}

.homepage-summary {
  color: var(--theme-black);
  background-color: transparent;
}

.picture-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 4em;
  /* border: 1px solid sienna; */
}


.image-container {
  filter: grayscale(100%);
  border-radius: 50%;
  max-width: 100%;
}

@media screen and (max-width: 950px){
  .homepage-subgreeting {
    margin-right: 1.5em;
  }
}

@media screen and (max-width: 700px){
  .introBox {
    max-width: 100%;
    margin: 5em 0 5em 0;
    text-align: center;
    /* border: 1px solid black; */
  }

  .homepage-greeting {
    font-size: 2rem;
  }

  .nameBanner {
    font-size: 3.8rem;
  }

  .roleBanner {
    font-size: 2rem;
  }

  .homepage-subgreeting {
    max-width: 100%;
    text-align: center;
    margin: 0 0 2em 0;
    font-size: 1.6rem;
    /* border: 1px solid black; */
  }

  .picture-container {
    justify-content: center;
    width: 100%;
    /* border: 1px solid black; */
  }

  .image-container {
    scale: .75;
  }
}

@media screen and (max-width:400px){
  .nameBanner {
    font-size: 3rem;
  }
  
  .homepage-subgreeting {
    font-size: 1.4rem;
    margin: 0 1em 0 1em;
  }

  .image-container {
    height: 250px;
  }
}