.contact-content {
  position: absolute;
  left: 5vw;
  right: 5vw;
  height: 100vh;
  max-width: 90vw;
  /* border: 1px solid red; */
}

.page-title {
  color: var(--theme-black);
  font-size: 2.4rem;
  padding: 0 1em;
  margin: 10rem 0 5rem 0;
  max-width: 100%;
  text-align: center;
  /* border: 1px solid black; */
}

.contact-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  padding: 1em;
  margin: 0 auto;
  /* border: 1px solid black; */
}

.form-label {
  color: var(--theme-black);
  font-size: 2.4rem;
  /* border: 1px solid red; */
}

.form-input {
  color: var(--theme-black);
  border-color: var(--theme-darkgrey);
  border-radius: 1rem;
  border-width: .4rem;
  font-size: 2rem;
  width: 100%;
}

::placeholder {
  color: var(--theme-lightgrey);
}

.form-submit {
  color: var(--theme-black);
  font-size: 1.8rem;
  border-color: var(--theme-lightgrey);
  border-radius: 1rem;
  border-width: .5rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  transition: all 400ms ease-in-out;
}

.form-submit:hover {
  transform: scale(1.05);
  color: var(--milkshake-green);
}

.form-message {
  color: var(--theme-darkgrey);
  border-color: var(--theme-black);
  border-width: 3px;
  border-radius: 10px;
  font-size: 20px;
  height: 120px;
  max-width: 100%;
}

@media screen and (max-width: 400px){
  .page-title {
    margin: 2rem 0 5rem 0;
  }
}

@media screen and (max-width:300px){
  .contact-content {
    left: 5vw;
    right: 5vw;
    min-width: 50vw;
    /* border: 1px solid green; */
  }
  
  .page-title {
    margin-bottom: 0;
  }

  .form-label {
    font-size: 1.8rem;
    width: max-content;
  }

  .form-input {
    width: 50%;
    font-size: 1.4rem;
  }

  .form-submit {
    margin-top: 1em;
    margin-left: 0;
  }
}

