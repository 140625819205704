@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

:root {
  /* Animation Speed */
  --animspeed: 500ms;

  /* Main Theme 2022*/
  --theme-background: #ffffff;
  --theme-mainAccent: #f19dac;
  --theme-black: #000000;
  --theme-darkgrey: #6c757d;
  --theme-lightgrey: #ced4da;
  --theme-hovercolor: #e9ecef;

  /* Milkshake Theme */
  --milkshake-light: #ffffff;
  --milkshake-dark: #212b43;
  --milkshake-lightblue: #62cfe6;
  --milkshake-red: #f19dac;
  --milkshake-yellow: #f6f4a0;
  --milkshake-green: #73e4d0;
  --milkshake-purple: #ba96db;

  font-size: 10px;
}

* {
  background-color: var(--theme-background);
  font-family: "Roboto Mono";
}
