.nav-header {
  position: fixed;
  width: 5vw;
  min-height: 100vh;
  overflow: auto;
  left: 0;
  /* border: 1px solid green; */
}

.nav-header-container {
  position: absolute;
  width: 100%;
  height: 25%;
  margin-top: 40vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.nav-header-img {
  max-width: 100%;
  margin: 0 auto 0 auto;
  /* border: 1px solid black; */
}

/* Nav Link Styling */

.navLink {
  color: var(--theme-black);
  text-decoration: none;
  transition: all 500ms ease-in-out;
  /* border: 1px solid red; */
}

.navLink:hover {
  color: var(--theme-mainAccent);
}

@media screen and (max-height: 700px){
  .nav-header-container {
    height: 75%;
    margin: 8em 0 8em 0;
  }
}

@media screen and (max-height:400px){
  .nav-header-container {
    height: 100%;
    margin: 0;
  }
}

@media screen and (max-width:700px){
  .nav-header-img {
    display: none;
  }
}